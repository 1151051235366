<template>
  <v-container id="locationHours" fluid tag="section" class="ma-4 pa-8 white rounded-lg">
    <alert-notification :message="alert" />
    <h1>{{ $t('admin.sub.deliveryZones.subtitle') }}</h1>
    <v-row v-for="zone in deliveryZones" :key="'deliveryZone' + zone.id" class="text-capitalize text-center">
      <v-col cols="6" lg="2">
        {{ zone.city }}
      </v-col>
      <v-col cols="6" lg="2">
        ({{ zone.zipcode }})
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';

  export default {
    name: 'DeliveryZones',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        loading: false,
        deliveryZones: []
      };
    },
    mounted: async function () {
      await this.refreshDeliveryZones();
    },
    methods: {
      async refreshDeliveryZones () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'location/delivery/zones');
          if (response.data !== undefined && response.data.deliveryZones !== undefined) {
            this.deliveryZones = response.data.deliveryZones;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
